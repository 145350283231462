import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@jsverse/transloco';
// import { AngularToastifyModule } from 'angular-toastify';
// import { LanguagesModule } from 'app/layout/common/languages/languages.module';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        TranslocoModule,
        MatTooltipModule,
        ReactiveFormsModule
    ],
    exports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        TranslocoModule,
        MatTooltipModule,
        ReactiveFormsModule
    ]
})
export class SharedModule
{
}
